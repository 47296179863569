import AITextCompletion from 'models/ai_text_completion';
import CreateToast from 'scripts/application/actions/toast/create_toast';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import { translateText, getErrorToastParams } from 'scripts/application/actions/lib/ai_translate';
import Translation from 'scripts/domain/models/translation';
import TranslationRequest from 'scripts/domain/models/translation_request';

export default class TranslateComposition {
  constructor(context) {
    this.context = context;
  }

  run(params) {
    const { composition, requestorId, text, targetLanguage, customerId } = params;
    const { aiTextCompletions, compositions } = this.context.stores.customers.storesFor(customerId);

    const translationRequest = TranslationRequest.create({
      text,
      targetLanguage,
    });

    aiTextCompletions.setLoading({ id: requestorId });
    aiTextCompletions.clearErrorForLoading({ id: requestorId });

    return translateText(customerId, this.context, translationRequest)
      .then(responseDto => {
        if (!responseDto) {
          return;
        }

        // The text entered by the agent is saved in the 'translation' object
        // The parent content will be replaced by the response to send to customer.
        const translation = Translation.create({
          content: text,
          contentLanguage: responseDto.sourceLanguage,
          parentLanguage: responseDto.targetLanguage,
          model: responseDto.model,
          provider: responseDto.provider,
          translatedAt: responseDto.translatedAt,
        });

        composition.replaceTranslation(translation);
        compositions.replace(composition);
        this.context.stores.localCompositions.set(customerId, composition);

        // Render the text completion bubble with the content in it.
        const completionParams = {
          data: {
            initialText: text,
            customerId,
            requestorId,
            text: responseDto.text,
          },
          id: requestorId,
          rewriteId: IdGenerator.newId(),
          type: AITextCompletion.Types.translate,
        };
        const result = AITextCompletion.create(completionParams);
        aiTextCompletions.addOrReplace(result);

        return translation;
      })
      .catch(err => {
        const toastParams = getErrorToastParams(err);
        this.context.executeAction(CreateToast, toastParams);
      })
      .finally(() => {
        aiTextCompletions.resetLoading({ id: requestorId });
      });
  }
}
