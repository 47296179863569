import URLSafeBase64 from 'urlsafe-base64';
import { v5 } from 'uuid';
import _ from 'lodash';

import ExternalDataObjectEnvelope from 'models/external_data_objects/external_data_object_envelope';
import Err from 'models/err';
import qconsole from 'scripts/lib/qconsole';
import RefreshAllExternalDataObjects from 'actions/external_data/refresh_all_external_data_objects';
import { simpleHash } from 'scripts/adapters/lib/simple_hash';

// The purpose of the "namespace" is to provide a seed for the UUID.v5() generator so that it creates
// repeatable IDs (identical input would produce identical output)
const ID_NAMESPACE = '3f531d2e-bef3-45c8-9cc5-d6e7f892cfda';

/**
 * We debounce the broadcast handler in order to deal with the "feedback loop" that may occur
 * when the app platform runs side by side with the legacy Lookup Adapters.
 */
export const BROADCAST_DEBOUNCE_DELAY = 10 * 1000;

/**
 * This Observer deals with customer-level custom data objects only.
 */
export default class ExternalDataGatewayObserver {
  constructor(context) {
    this.context = context;
    this.scheduledRequests = {};
  }

  onFetchSuccess(envelope, requestParams = {}, queryParams = {}) {
    // Did we receive all expected parameters? Is the customer still loaded?
    if (!this._verifyParameters(queryParams, 'ExternalDataGatewayObserver.onFetchSuccess')) return;

    // Extract query parameters
    const { context: customerId, gladlyEntityType, gladlyEntityId, namespace, requestor, ...extraParams } = queryParams;

    // Did we receive the payload?
    if (!envelope?.data) {
      const err = new Error('ExternalDataGatewayObserver.onFetchSuccess: missing "data" payload');
      this.context.errorReporter.reportError(err, {
        tags: { customerId },
        extra: { ...queryParams },
        message: 'Unexpected external data response: missing or invalid data payload',
      });
      return this.onFetchError(err, requestParams, queryParams);
    }

    const store = this.context.stores.customers.storesFor(customerId).externalDataObjects;
    const entityKey = this._getEntityKey(queryParams);

    const itemId = createItemId(entityKey);
    const item = ExternalDataObjectEnvelope.create({
      id: itemId,
      data: envelope.data,
      extraParams: _.omit(extraParams, ['refresh']),
      parentEntityId: gladlyEntityId,
      parentEntityType: gladlyEntityType,
      namespace,
      requestorId: requestor,
    });

    store.addOrReplace(item);
    store.resetLoading(entityKey);
    store.clearErrorForLoading(entityKey);
  }

  onFetchError(err, requestParams, queryParams) {
    qconsole.error(`Failed to fetch External Data. Query [${JSON.stringify(queryParams)}]`, JSON.stringify(err));

    // Did we receive all expected parameters?
    if (!this._verifyParameters(queryParams, 'ExternalDataGatewayObserver.onFetchError')) return;

    const { context: customerId } = queryParams;
    const store = this.context.stores.customers.storesFor(customerId).externalDataObjects;
    const entityKey = this._getEntityKey(queryParams);

    store.setErrorForLoading(
      new Err({
        code: Err.Code.UNEXPECTED_ERROR,
        detail: err.message || err,
      }),
      entityKey
    );
    store.resetLoading(entityKey);
  }

  /**
   * Handles the backend "external data deleted" broadcast and attempts to re-ingest the data
   *
   * @param {*} payload - Typically, this would be the broadcasted data. In case of External Data Objects, we do not
   * broadcast the actual data objects and instead use the broadcast to trigger re-fetching. Currently, we ignore
   * the `payload`
   * @param {Object} topicParams - The actual parameters parsed out of the broadcast topic - we use them to find
   * the custom data objects that need to be re-requested
   */
  onBroadcastDelete(payload, topicParams) {
    const { gladlyEntityId, gladlyEntityType } = topicParams || {};
    if (!gladlyEntityId || !gladlyEntityType) {
      return this.context.errorReporter.reportError(
        new Error('ExternalDataGatewayObserver.onBroadcastDelete: missing or invalid parameters. Ignoring broadcast.'),
        {
          extra: { ...topicParams },
          message: 'Missing or invalid parameters',
        }
      );
    }

    // Express our interest in the data refresh for this ID
    this._scheduleRefresh(gladlyEntityId, gladlyEntityType);
  }

  _isCustomerLoaded(customerId) {
    return !!customerId && this.context.stores.customers.has({ id: customerId });
  }

  _getEntityKey(queryParams) {
    const { namespace, gladlyEntityType, gladlyEntityId, requestor: requestorId } = queryParams || {};

    return {
      namespace,
      parentEntityId: gladlyEntityId,
      parentEntityType: gladlyEntityType,
      requestorId,
    };
  }

  _scheduleRefresh(gladlyEntityId, gladlyEntityType) {
    const requestRefresh = (entityId, entityType) => {
      this.context.executeAction(RefreshAllExternalDataObjects, {
        dataObjectParams: {
          parentEntityId: entityId,
          parentEntityType: entityType,
        },
        requestDataRefresh: true,
      });

      delete this.scheduledRequests[entityId];
    };

    if (!this.scheduledRequests[gladlyEntityType]) {
      this.scheduledRequests[gladlyEntityType] = setTimeout(
        () => requestRefresh(gladlyEntityId, gladlyEntityType),
        BROADCAST_DEBOUNCE_DELAY
      );
    }
  }

  _verifyParameters(queryParams, methodName) {
    const requiredParams = ['context', 'namespace', 'requestor'];

    for (const paramName of requiredParams) {
      if (_.get(queryParams, paramName)) continue;

      this.context.errorReporter.reportError(new Error(`${methodName}: invalid query`), {
        extra: { ...queryParams },
        message: `Missing or invalid query parameter "${paramName}"`,
      });
      return false;
    }

    const customerId = queryParams?.context;
    if (!this._isCustomerLoaded(customerId)) {
      qconsole.log(`${methodName}: received data for unloaded customer [${customerId}]. Ignoring.`);
      return false;
    }

    return true;
  }
}

/**
 * Create a readable "item ID" based on the item "composite key". It is done by creating a simple hash and
 * then using it as an argument for UUID v5(). We encode the resulting ID in the same way as the other IDs
 * in Agent Desktop
 *
 * @param {Object} itemKey - "composite key" of the item.
 */
function createItemId(itemKey) {
  const hash = simpleHash(itemKey);
  const buffer = new Buffer(16);

  v5(hash, ID_NAMESPACE, buffer);
  return URLSafeBase64.encode(buffer);
}
