import _ from 'lodash';
import { faBriefcase } from '@fortawesome/pro-regular-svg-icons/faBriefcase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Range } from 'slate';
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { usePlateEditorState } from '@udecode/plate';

import AgentAssistanceConfigContext from 'components/contexts/agent_assistance_config_context';
import AIFriendlyIcon from 'components/lib/icons/ai_friendly';
import AIRephraseIcon from 'components/lib/icons/ai_rephrase';
import AILengthenIcon from 'components/lib/icons/ai_lengthen';
import AIShortenIcon from 'components/lib/icons/ai_shorten';
import AITranslateIcon from 'components/lib/icons/ai_translate';
import AITextCompletion from 'models/ai_text_completion';
import AITextCompletionContext from 'components/text_editor_new/plugins/ai/components/ai_text_completion_context';
import CustomerContext from 'components/customer/customer_context';
import { Item } from 'components/text_editor_new/components/controls/controls_new';
import Tooltip from 'components/common/tooltip';
import { useDebounced } from 'components/hooks/debounce_hooks';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';
import useToggleAICompletion from 'components/text_editor_new/plugins/ai/ai_text_completion/use_toggle_ai_completion';
import { Separator } from 'components/composer/shared/editor_controls_new';

const TOOLTIP_DELAY = 200;

export default function AITextRewriteControls() {
  const agentAssistanceConfig = useContext(AgentAssistanceConfigContext);
  const includeAIFeatures = !agentAssistanceConfig.disableHeroAI;
  if (!includeAIFeatures) {
    return null;
  }

  return (
    <>
      <AITextCompletionButtons />
      <Separator />
    </>
  );
}

export function AITextCompletionButtons() {
  const editor = usePlateEditorState();
  const props = {
    isCollapsed: editor.selection && Range.isCollapsed(editor.selection),
    isDisabled: editor.shouldDisableTextCompletion(),
  };

  return (
    <>
      <AIMakeFriendly {...props} />
      <AIMakeProfessional {...props} />
      <AIRephrase {...props} />
      <AIShorten {...props} />
      <AILengthen {...props} />
      <AITranslationButton {...props} />
    </>
  );
}

export function AITranslationButton(props) {
  const { customerLanguage } = useContext(CustomerContext);
  const isFeatureEnabled = useIsFeatureEnabled();

  if (!isFeatureEnabled('translationAI')) {
    return null;
  }

  return (
    <>
      <Separator />
      <AITranslate {...props} isDisabled={!customerLanguage} />
    </>
  );
}

export const AITranslate = withAIRewriteButton({
  'data-aid': 'aiTranslateButton',
  renderIcon: ({ isDisabled }) => <StyledTranslateIcon $isDisabled={isDisabled} />,
  type: AITextCompletion.Types.translate,
});

const AIMakeFriendly = withAIRewriteButton({
  'data-aid': 'aiMakeFriendlyButton',
  renderIcon: ({ isDisabled }) => <StyledFriendlyIcon $isDisabled={isDisabled} />,
  type: AITextCompletion.Types.make_friendly,
});

const AIMakeProfessional = withAIRewriteButton({
  'data-aid': 'aiMakeProfessionalButton',
  renderIcon: () => <FontAwesomeIcon icon={faBriefcase} />,
  type: AITextCompletion.Types.make_professional,
});

const AIRephrase = withAIRewriteButton({
  'data-aid': 'aiRephraseButton',
  renderIcon: ({ isDisabled }) => <StyledRephraseIcon $isDisabled={isDisabled} />,
  type: AITextCompletion.Types.rephrase,
});

const AIShorten = withAIRewriteButton({
  'data-aid': 'aiShortenButton',
  renderIcon: ({ isDisabled }) => <StyledShortenIcon $isDisabled={isDisabled} />,
  type: AITextCompletion.Types.make_shorter,
});

const AILengthen = withAIRewriteButton({
  'data-aid': 'aiLengthenButton',
  renderIcon: ({ isDisabled }) => <StyledLengthenIcon $isDisabled={isDisabled} />,
  type: AITextCompletion.Types.make_longer,
});

export { AIMakeFriendly, AIMakeProfessional, AIRephrase, AIShorten, AILengthen };

const StyledFriendlyIcon = styled(AIFriendlyIcon)`
  stroke: ${p => (p.$isDisabled ? p.theme.colors.gray300 : p.theme.colors.black)};
`;

const StyledRephraseIcon = styled(AIRephraseIcon)`
  stroke: ${p => (p.$isDisabled ? p.theme.colors.gray300 : p.theme.colors.black)};
`;

const StyledLengthenIcon = styled(AILengthenIcon)`
  stroke: ${p => (p.$isDisabled ? p.theme.colors.gray300 : p.theme.colors.black)};
`;

const StyledShortenIcon = styled(AIShortenIcon)`
  stroke: ${p => (p.$isDisabled ? p.theme.colors.gray300 : p.theme.colors.black)};
`;

const StyledTranslateIcon = styled(AITranslateIcon)`
  stroke: ${p => (p.$isDisabled ? p.theme.colors.gray300 : p.theme.colors.black)};
`;

export function withAIRewriteButton({ 'data-aid': dataAid, renderIcon, type }) {
  function Component({ className, isCollapsed, isDisabled }) {
    const { completion } = useContext(AITextCompletionContext);
    const isActive = completion?.type === type;

    const onClick = useDebounced(useToggleAICompletion(type), 50);

    const [itemProps, tooltipProps] = useTemporarilyHideTooltipOnMouseDown(isDisabled);
    const tooltipMessage = getTooltipMessage(type, isDisabled, isCollapsed);

    return (
      <Tooltip delay={TOOLTIP_DELAY} message={tooltipMessage} {...tooltipProps}>
        <Item
          className={className}
          data-aid={dataAid}
          data-disabled={isDisabled}
          isActive={isActive}
          isDisabled={isDisabled}
          onClick={isDisabled ? _.noop : onClick}
          {...itemProps}
        >
          {renderIcon({ isDisabled })}
        </Item>
      </Tooltip>
    );
  }

  Component.displayName = `AIRewriteButton(${_.upperFirst(_.startCase(type))})`;
  return Component;
}

// When the user clicks on the button, we want to hide the tooltip. This is partially
// to get around a _popover related bug where the tooltip starts soaring off into space
// when the button gets clicked and the preview is shown but.. it's also just a better
// user experience IMO.
//
// We do want to keep the tooltip visible when the button is disabled, since it tells them
// why it's not clickable when it's disabled.
function useTemporarilyHideTooltipOnMouseDown(isDisabled) {
  const [isVisible, setIsVisible] = useState(true);

  const onMouseDown = useCallback(
    evt => {
      if (!isDisabled) {
        setIsVisible(false);
        evt.preventDefault();
      }
    },
    [isDisabled]
  );

  // It might seem like the tooltip should become visible again on mouse up,
  // but internally the tooltip waits for another mouse enter event before it
  // becomes visible again.
  const onMouseUp = useCallback(() => {
    if (!isDisabled) {
      setIsVisible(true);
    }
  }, [isDisabled]);

  const finalIsVisible = isDisabled ? undefined : isVisible;

  let itemProps = { onMouseDown, onMouseUp };
  let tooltipProps = { isVisible: finalIsVisible };
  return [itemProps, tooltipProps];
}

function getTooltipMessage(type, isDisabled, isCollapsed) {
  if (isDisabled) {
    return DISABLED_TOOLTIP;
  }

  if (type === AITextCompletion.Types.make_friendly) {
    return isCollapsed ? FRIENDLY_ALL_TOOLTIP : FRIENDLY_SELECTED_TOOLTIP;
  } else if (type === AITextCompletion.Types.make_professional) {
    return isCollapsed ? PROFESSIONAL_ALL_TOOLTIP : PROFESSIONAL_SELECTED_TOOLTIP;
  } else if (type === AITextCompletion.Types.rephrase) {
    return isCollapsed ? REPHRASE_ALL_TOOLTIP : REPHRASE_SELECTED_TOOLTIP;
  } else if (type === AITextCompletion.Types.make_shorter) {
    return isCollapsed ? SHORTEN_ALL_TOOLTIP : SHORTEN_SELECTED_TOOLTIP;
  } else if (type === AITextCompletion.Types.make_longer) {
    return isCollapsed ? LENGTHEN_ALL_TOOLTIP : LENGTHEN_SELECTED_TOOLTIP;
  } else if (type === AITextCompletion.Types.translate) {
    // Translation only works on the entire message.
    return TRANSLATE_ALL_TOOLTIP;
  } else {
    return '';
  }
}

const DISABLED_TOOLTIP = 'Please write something first';

const FRIENDLY_ALL_TOOLTIP = 'Make all text more friendly';
const FRIENDLY_SELECTED_TOOLTIP = 'Make selected text more friendly';

const PROFESSIONAL_ALL_TOOLTIP = 'Make all text more professional';
const PROFESSIONAL_SELECTED_TOOLTIP = 'Make selected text more professional';

const REPHRASE_ALL_TOOLTIP = 'Rephrase all text';
const REPHRASE_SELECTED_TOOLTIP = 'Rephrase selected text';

const SHORTEN_ALL_TOOLTIP = 'Make all text shorter';
const SHORTEN_SELECTED_TOOLTIP = 'Make selected text shorter';

const LENGTHEN_ALL_TOOLTIP = 'Make all text longer';
const LENGTHEN_SELECTED_TOOLTIP = 'Make selected text longer';

const TRANSLATE_ALL_TOOLTIP = 'Translate all the text';
