import PropTypes from 'prop-types';
import React from 'react';

import { CHANNEL_ACTION_TEXT_WITH_SUBJECT } from 'components/lib/channel_action_text';
import connect from 'components/lib/connect';
import ConversationItem from 'models/conversation_item';
import { iconClassNameForItem } from './metadata_icon_classes';
import ItemPrepositionalPhrases from './item_prepositional_phrases';
import Metadata from './metadata';
import TranslationLanguages from 'models/languages/translation_languages';
import { typeReflect } from 'models/conversation_item/content_from_js';

export function CustomerMetadata({
  customerName,
  hasError,
  icon,
  iconClass,
  isCollapsed,
  isTranslationEnabled,
  item,
  onToggleCollapse,
  subject,
  text,
  tooltip,
}) {
  hasError = hasError !== undefined ? hasError : item.content.hasErrorStatus && item.content.hasErrorStatus();
  iconClass = iconClass || iconClassNameForItem(item);
  subject = subject !== undefined ? subject : customerName || item.from();

  const contentType = typeReflect.instanceToType(item.content);

  text = text || CHANNEL_ACTION_TEXT_WITH_SUBJECT[contentType];
  let language =
    isTranslationEnabled && item.translation ? TranslationLanguages[item.translation.parentLanguage] : undefined;

  return (
    <Metadata
      hasError={hasError}
      icon={icon}
      iconClass={iconClass}
      isCollapsed={isCollapsed}
      isInbound
      language={language}
      onToggleCollapse={onToggleCollapse}
      prepositionalPhrases={<ItemPrepositionalPhrases item={item} />}
      subject={subject}
      text={text}
      timestamp={item.timestamp}
      tooltip={tooltip}
    />
  );
}

CustomerMetadata.propTypes = {
  customerName: PropTypes.string,
  hasError: PropTypes.bool,
  icon: PropTypes.node,
  iconClass: PropTypes.string,
  isCollapsed: PropTypes.bool,
  isTranslationEnabled: PropTypes.bool,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  onToggleCollapse: PropTypes.func,
  subject: PropTypes.string,
  text: PropTypes.node,
  tooltip: PropTypes.string,
};

function mapStateToProps({ getProvider, isFeatureEnabled }, props) {
  let profile = getProvider('profile').get();
  let initiator = props.item.initiator;
  let isCurrentCustomer = profile && profile.id === initiator.id;
  let isTranslationEnabled = isFeatureEnabled('translationAI');

  return {
    ...props,
    customerName: isCurrentCustomer ? profile.name : initiator.name,
    isTranslationEnabled,
  };
}

const CustomerMetadataContainer = connect(mapStateToProps)(CustomerMetadata);
export default CustomerMetadataContainer;
