import _ from 'lodash';

import Communicator, { inboundCommunicators } from './communicator';
import contentFromJs, { typeReflect } from './conversation_item/content_from_js'; // must be before content_classes
import ContentClasses from './conversation_item/content_classes';
import ContentPlaceholder from './content_placeholder';
import ConversationItemStatus from './conversation_item_status';
import ConversationItemType from './conversation_item_type';
import createModel, { prop } from './lib/create_model';
import { formatPhoneNumber } from 'models/phone_number';
import getClassName from 'scripts/lib/class_name';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import LinkedItem from 'models/linked_item';
import PhoneCall from './phone_call';
import RelatedSnippetId from './related_snippet_id';
import ServerClock from 'scripts/application/lib/server_clock';
import { Translation } from './translation';
import { TranslatableConversationItemTypes } from './conversation_item_type';

const {
  ABANDONED_CALL,
  CHAT_MESSAGE,
  CHAT_SELF_SERVICE_METADATA,
  CONVERSATION_MESSAGE,
  CUSTOM_CHANNEL_MESSAGE,
  EMAIL,
  EMAIL_PREVIEW,
  MESSAGE_AUTOMATION, // deprecated
  MESSAGE_AUTOMATION_MESSAGE,
  MESSAGE_AUTOMATION_SUMMARY,
  PAYMENT_STATUS_EVENT,
  PHONE_CALL,
  SMS,
  TWITTER,
  VOICEMAIL,
  WHATSAPP,
} = ConversationItemType;
const ConversationItemContentClasses = ContentClasses.map(a => a[1]);

const RelatedItemId = createModel({
  modelName: 'RelatedItemId',

  properties: {
    conversationItemId: String,
    timestamp: String,
  },
});

const ConversationItem = createModel({
  modelName: 'ConversationItem',

  properties: {
    id: prop(String).isRequired,
    conversationId: String,
    content: prop()
      .oneOf(...ConversationItemContentClasses)
      .fromJs(contentFromJs),
    customerId: prop(String).isRequired,
    initiator: {
      type: String,
      id: String,
      name: String, // used in linked items
    },
    linkedItems: prop([LinkedItem]).default([]),
    relatedItemId: RelatedItemId,
    responder: {
      type: String,
      id: String,
    },
    snippetIds: [String], // Use relatedSnippetIds going forward to track the language and channel type used
    relatedSnippetIds: prop([RelatedSnippetId]).default([]),
    translation: Translation,
    status: ConversationItemStatus,
    timestamp: String,
    version: prop(Number).default(0),
  },

  hasActivePhoneCall() {
    return this.content instanceof PhoneCall && this.content.isActive();
  },

  hasOfferingOrLivePhoneCall() {
    return this.content instanceof PhoneCall && this.content.isOfferingOrLiveCall();
  },

  hasLivePhoneCall() {
    return this.content instanceof PhoneCall && (this.content.isLive() || this.content.isOutgoing());
  },

  isTranslatable() {
    return TranslatableConversationItemTypes.includes(this.contentType());
  },

  isAgentItem() {
    return this.initiator && this.initiator.type === Communicator.AGENT;
  },

  isCustomerItem() {
    return isCustomerItem(this);
  },

  isApiItem() {
    return this.initiator && this.initiator.type === Communicator.API;
  },

  isAutomatedItem() {
    return this.initiator && this.initiator.type === Communicator.AUTOMATED;
  },

  isInbound() {
    const itemType = this.contentType();
    switch (itemType) {
      case MESSAGE_AUTOMATION_SUMMARY:
        return false;
      default:
        return this.initiator && _.includes(inboundCommunicators, this.initiator.type);
    }
  },

  isPlaceholder() {
    return this.content instanceof ContentPlaceholder;
  },

  isLink() {
    let li = this.linkedItems;
    return !!(li && li.length && li[0].isOriginal);
  },

  from() {
    const itemType = this.contentType();
    switch (itemType) {
      case CHAT_MESSAGE:
      case CHAT_SELF_SERVICE_METADATA:
      case PAYMENT_STATUS_EVENT:
        return 'Customer';
      case CONVERSATION_MESSAGE:
        return this.content.formatCustomerAddress();
      case CUSTOM_CHANNEL_MESSAGE:
        return 'Customer';
      case EMAIL:
      case EMAIL_PREVIEW:
        return this.content.from;
      case ABANDONED_CALL:
      case PHONE_CALL:
        return formatPhoneNumber(this.content.customerNumber);
      case MESSAGE_AUTOMATION_MESSAGE:
        return this.content.from();
      case MESSAGE_AUTOMATION_SUMMARY:
      case MESSAGE_AUTOMATION: // deprecated
        return 'Automation service';
      case SMS:
      case WHATSAPP:
        return formatPhoneNumber(this.content.from);
      case TWITTER:
        return this.content.user.screenName;
      case VOICEMAIL:
        return formatPhoneNumber(this.content.customerNumber);
    }
  },

  replaceContent(newContent) {
    if (!(newContent instanceof this.content.constructor)) {
      throw new Error(`expected [${this.content.constructor.name}] content but got [${getClassName(newContent)}]`);
    }
    this.content = newContent;
  },

  replaceTranslation(newTranslation) {
    this.translation = newTranslation;
  },

  respond(communicatorType, communicatorId) {
    this.responder = {
      type: communicatorType,
      id: communicatorId,
    };
  },

  contentType() {
    return typeReflect.instanceToType(this.content);
  },

  addLinkedItem(linkedItem) {
    this.linkedItems.push(linkedItem);
  },

  removeLinkedItem(linkedItemId) {
    const linkedItemIndex = _.findIndex(this.linkedItems, item => item.itemId === linkedItemId);
    if (linkedItemIndex < 0) {
      return;
    }
    this.linkedItems.splice(linkedItemIndex, 1);
  },

  incrementVersion() {
    this.version = (this.version || 0) + 1;
  },

  overrideToJs(toJs) {
    return () => {
      let obj = toJs();
      if (obj.content) {
        obj.content.type = this.contentType();
      }
      if (obj.linkedItems && !obj.linkedItems.length) {
        delete obj.linkedItems;
      }
      return obj;
    };
  },

  statics: {
    create(attrs) {
      let defaults = {
        id: IdGenerator.newId(),
        timestamp: ServerClock.toISOString(),
      };

      return new this(_.merge({}, defaults, attrs));
    },

    createPlaceholder(id, attrs, props = {}) {
      return new this({ id, ...attrs, content: new ContentPlaceholder(props) });
    },

    overrideFromJs(fromJs) {
      return attrs => {
        if (!attrs.content) {
          throw new Error(`could not hydrate conversation item [${attrs.id}]: missing content`);
        }

        try {
          return fromJs(attrs);
        } catch (e) {
          throw new Error(`could not hydrate conversation item [${attrs.id}]: ${e.message}`);
        }
      };
    },
  },
});

export function getMessagePreview(conversationItemContent) {
  const MAX_PREVIEW_LENGTH = 180;
  const message =
    (_.isFunction(conversationItemContent.getMessageText) && conversationItemContent.getMessageText()) || '';

  return message.substring(0, MAX_PREVIEW_LENGTH);
}

export function getOutgoingCommunicationStatus(item) {
  return (item.content.getStatus && item.content.getStatus()) || undefined;
}

export function isCustomerItem(ci) {
  return ci.initiator?.type === Communicator.CUSTOMER;
}

export default ConversationItem;
