import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import AnswerContentEditor from './answer_content_editor';
import AnswerEditorInfoPanel from './answer_editor_info_panel';
import AnswerErrLink from './answer_error_link';
import AnswerZeroState from './answer_zero_state';
import Button, { ButtonTypes } from 'components/common/button';
import Err from 'models/err';
import { getAttrForLanguage } from 'models/answers/snippet_validators';
import { default as Languages } from 'models/languages/answer_languages';
import Snippet, { ChannelFieldName } from 'models/answers/snippet';
import SnippetRevision from 'models/snippet_revision';

class AnswerEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusField: null,
      hasModified: {},
      language: _.get(this.props, 'snippet.contents[0].language'),
      selectedChannelType: '',
      showErrors: false,
    };
    _.bindAll(this, [
      'addAnswerForChannel',
      'changeSelectedChannel',
      'focusCard',
      'getError',
      'getOnChange',
      'hasChannel',
      'isFocused',
      'isModified',
      'markModified',
      'onConfirmDelete',
      'onNameChange',
      'onStartUpload',
      'onSubmit',
      'setFocus',
      'setInternal',
      'setLanguage',
      'setModified',
      'unsetFocus',
    ]);
  }

  componentDidMount() {
    if (!this.props.readOnly) {
      let nameInput = _.get(this, 'infoPanel.nameInput');
      nameInput && nameInput.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors.length) {
      this.setState({ showErrors: true });
    }
  }

  /* Getters */

  getError(field) {
    let languageField = this.getFieldName(field);
    return (
      this.state.showErrors &&
      !this.state.hasModified[languageField] &&
      _.find(this.props.errors, error => error.attr === languageField)
    );
  }

  getNewModified(field) {
    let languageField = this.getFieldName(field);

    return _.merge({}, this.state.hasModified, { [languageField]: true });
  }

  getUsedInRules(errors) {
    return errors
      .filter(err => err.code === Err.Code.IN_USE)
      .map(err => {
        let matches = err.detail && err.detail.match(/\[(.*?)\]/);
        return matches ? matches[1] : null;
      });
  }

  getCompositionRef(channel) {
    return this.contentEditor && this.contentEditor.getCompositionRef(channel);
  }

  /* Setters */

  setInternal(isInternal) {
    this.props.updateFieldForLanguage({ language: this.state.language, fieldName: 'isInternal', field: isInternal });
  }

  setModified(hasModified) {
    this.setState({ hasModified });
  }

  setLanguage(language) {
    if (!_.find(this.props.contents, c => c.language === language)) {
      this.props.addLanguage(language);
    }
    this.setState({ language, selectedChannelType: '' });
  }

  setFocus(field) {
    let languageField = this.getFieldName(field);
    this.setState({ focusField: languageField });
  }

  unsetFocus() {
    this.setState({ focusField: null });
  }

  /* Handlers */
  onConfirmDelete() {
    const channelType = this.state.selectedChannelType;
    this.props.removeChannel({ channelType, language: this.state.language });
    this.setState({
      selectedChannelType: '',
    });
  }

  onNameChange(evt) {
    this.props.setName(evt.target.value);
    this.markModified('name');
  }

  onSubmit() {
    this.setState({ hasModified: {}, showErrors: false });
    this.props.saveDraft();
  }

  changeSelectedChannel(selectedChannelType, callback = _.noop) {
    this.setState({ selectedChannelType }, callback);
  }

  focusCard(channel) {
    this.changeSelectedChannel(channel);
    this.contentEditor && this.contentEditor.scrollToCard(channel);
    const composition = this.getCompositionRef(channel);
    if (composition) {
      const onChange = this.getOnChange(ChannelFieldName[channel]);
      onChange(composition.editor.focusAtEnd());
    }
  }

  addAnswerForChannel(channelType) {
    this.setState({ selectedChannelType: channelType });
    this.props.addChannel({ language: this.state.language, channelType });
  }

  onStartUpload(file, contentChannelType, onInsertImage, isInline) {
    this.props.onStartUpload({ file, contentChannelType, onInsertImage, isInline, language: this.state.language });
  }

  /* Render */

  render() {
    return (
      <div className="answerEditor-page">
        <div className="answerEditor-body">
          {this.renderInfoPanel()}
          {this.renderEditingPanel()}
        </div>
        <div className="answerEditor-footer">
          {this.renderError()}
          {!this.props.readOnly && (
            <Button
              buttonType={ButtonTypes.TEXT}
              className="answerEditor-footer-cancel"
              onClick={this.props.cancelDraft}
            >
              Cancel
            </Button>
          )}
          {!this.props.readOnly && (
            <Button
              buttonType={ButtonTypes.PRIMARY}
              className="answerEditor-footer-submit"
              onClick={this.onSubmit}
              onMouseDown={evt => evt.preventDefault()}
            >
              {this.props.isNewSnippet && !this.props.isRecommendedAnswer ? 'Create' : 'Save'}
            </Button>
          )}
        </div>
      </div>
    );
  }

  renderInfoPanel() {
    return (
      <AnswerEditorInfoPanel
        addAnswerForChannel={this.addAnswerForChannel}
        answer={this.props.snippet}
        audiences={this.props.audiences}
        contents={this.props.contents}
        errors={this.props.errors}
        focusCard={this.focusCard}
        getError={this.getError}
        hasChannel={this.hasChannel}
        language={this.state.language}
        name={this.props.name}
        onChange={this.onNameChange}
        readOnly={this.props.readOnly}
        ref={node => (this.infoPanel = node)}
        selectedChannelType={this.state.selectedChannelType}
        setAudiences={this.props.setAudiences}
        setFocus={this.setFocus}
        setLanguage={this.setLanguage}
        snippetRevisions={this.props.snippetRevisions}
        unsetFocus={this.unsetFocus}
      />
    );
  }

  renderEditingPanel() {
    let content = _.find(this.props.contents, c => c.language === this.state.language);
    if (!_.get(content, 'existingChannelTypes.length')) {
      return <AnswerZeroState addChannel={this.addAnswerForChannel} language={this.state.language} />;
    }

    return (
      <AnswerContentEditor
        answerMenuData={this.props.answerMenuData}
        changeSelectedChannel={this.changeSelectedChannel}
        description={this.props.description}
        errors={this.props.errors}
        focusField={this.state.focusField}
        getError={this.getError}
        getOnChange={this.getOnChange}
        hasModified={this.state.hasModified}
        key={this.state.language}
        markModified={this.markModified}
        mentionMenuData={this.props.mentionMenuData}
        name={this.props.name}
        onConfirmDelete={this.onConfirmDelete}
        onExpandAnswer={this.props.onExpandAnswer}
        onSearchAnswerMenu={this.props.onSearchAnswerMenu}
        onStartUpload={this.onStartUpload}
        readOnly={this.props.readOnly}
        ref={node => (this.contentEditor = node)}
        selectedChannelType={this.state.selectedChannelType}
        setDescription={this.props.setDescription}
        setFocus={this.setFocus}
        setInternal={this.setInternal}
        setLanguage={this.setLanguage}
        setModified={this.setModified}
        snippet={this.props.snippet}
        snippetLinks={this.props.snippetLinks}
        {...content}
      />
    );
  }

  getUsedInErrorMessage(errors, answerID) {
    let message;
    if (errors[0].meta && errors[0].meta.helpCenterID) {
      message = this.getHelpCenterErrorMessage(errors, answerID);
    } else if (errors[0].meta && errors[0].meta.ivrId) {
      message = this.getConsumerSelfServiceErrorMessage(errors, answerID);
    } else {
      let rulesUsedIn = this.getUsedInRules(errors);
      message = `Could not delete response, used in rule${errors.length > 1 ? 's' : ''}: ${rulesUsedIn.join(', ')}`;
    }
    return message;
  }

  getHelpCenterErrorMessage(errors, answerID) {
    return (
      <span>
        This public Answer cannot be deleted until it is removed from{' '}
        <AnswerErrLink href={`/admin/help-center/${errors[0].meta.helpCenterID}/sections/${errors[0].meta.sectionID}`}>
          this Help Center section.
        </AnswerErrLink>
      </span>
    );
  }

  getConsumerSelfServiceErrorMessage(errors, answerID) {
    return (
      <span>
        This answer cannot be deleted until it is removed from{' '}
        <AnswerErrLink href={`/admin/threads/${errors[0].meta.ivrId}`}>this</AnswerErrLink> consumer self service
        thread.
      </span>
    );
  }

  renderError() {
    let errors = this.props.errors;
    if (!this.state.showErrors || errors.length === 0) {
      return null;
    }

    let focusedFieldError = _.find(errors, err => this.isFocused(err.attr) && !this.isModified(err.attr));
    let firstUnmodifiedError = _.find(errors, err => !this.isModified(err.attr));
    let usedIn = errors.filter(err => err.code === Err.Code.IN_USE);

    let errorMessage = null;
    if (usedIn.length > 0) {
      errorMessage = this.getUsedInErrorMessage(errors, this.props.snippet.id);
    } else if (focusedFieldError) {
      const errorLanguage = getErrorLanguage(focusedFieldError);
      const errorString = errorLanguage ? `${errorLanguage} - ` : '';
      errorMessage = `${errorString}${_.upperFirst(focusedFieldError.detail)}`;
    } else if (firstUnmodifiedError) {
      const errorLanguage = getErrorLanguage(firstUnmodifiedError);
      const errorString = errorLanguage ? `${errorLanguage} - ` : '';
      errorMessage = `${errorString}${_.upperFirst(firstUnmodifiedError.detail)}`;
    } else {
      return null; // don't show errors for fields already modified
    }
    return <span className="answerEditor-footer-error">{errorMessage}</span>;
  }

  /* Helpers */

  hasChannel(channel) {
    let content = _.find(this.props.contents, c => c.language === this.state.language);
    return _.includes(content.existingChannelTypes, channel);
  }

  isModified(field) {
    let languageField = this.getFieldName(field);
    return this.state.hasModified[languageField];
  }

  isFocused(fieldName) {
    if (this.state.focusField) {
      return fieldName === this.state.focusField;
    }
    return false;
  }

  markModified(field) {
    this.setState({
      hasModified: this.getNewModified(field),
    });
  }

  getOnChange(channel) {
    return this.props.getChannelOnChangeForLanguage({ channel, language: this.state.language });
  }

  getFieldName(field) {
    return getAttrForLanguage({ language: this.state.language, field });
  }
}

AnswerEditor.propTypes = {
  addChannel: PropTypes.func.isRequired,
  addLanguage: PropTypes.func.isRequired,
  answerMenuData: PropTypes.array,
  cancelDraft: PropTypes.func.isRequired,
  contents: PropTypes.array.isRequired,
  description: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.instanceOf(Err)).isRequired,
  getChannelOnChangeForLanguage: PropTypes.func.isRequired,
  isNewSnippet: PropTypes.bool,
  isRecommendedAnswer: PropTypes.bool,
  mentionMenuData: PropTypes.array.isRequired,
  name: PropTypes.string,
  onExpandAnswer: PropTypes.func,
  onSearchAnswerMenu: PropTypes.func,
  onStartUpload: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  removeChannel: PropTypes.func.isRequired,
  saveDraft: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  snippet: PropTypes.instanceOf(Snippet).isRequired,
  snippetLinks: PropTypes.array,
  snippetRevisions: PropTypes.arrayOf(PropTypes.instanceOf(SnippetRevision)),
  updateFieldForLanguage: PropTypes.func.isRequired,
};

function getErrorLanguage(error) {
  return Languages[error.attr.split('.')[0]] || '';
}

export default AnswerEditor;
