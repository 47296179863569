import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// DEPRECATED - DO NOT USE. Use - /src/scripts/presentation/components/common/menu.jsx

export class Menu extends React.Component {
  componentDidMount() {
    this.positionMenu();
  }

  componentDidUpdate() {
    this.positionMenu();
  }

  render() {
    const { children, className, isOpen } = this.props;
    return (
      <div className={classnames('menu', { 'menu-open': isOpen }, className)} ref={node => (this.menu = node)}>
        {children}
      </div>
    );
  }

  positionMenu() {
    if (!this.props.isOpen || !this.props.positionInside || !this.menu) {
      return;
    }

    const menuPixels = this.menu.getBoundingClientRect();
    const boundingPixels = this.props.positionInside.getBoundingClientRect();
    const rightDiff = boundingPixels.right - menuPixels.right;

    if (rightDiff < 0) {
      this.menu.style.left = `${Math.floor(rightDiff - 10)}px`;
    }
  }
}

Menu.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  positionInside: PropTypes.any, // Set this to the surrounding DOM element if you're using position: absolute to prevent it from going offscreen
};

// purely styling
export function MenuItem({ children, className, isDisabled, onSelect, role }) {
  return (
    <div
      className={classnames('menuItem', { 'menuItem-disabled': isDisabled }, className)}
      data-disabled={isDisabled}
      onClick={!isDisabled ? handleClick : undefined}
      onKeyDown={handleKeyDown}
      role={role}
      tabIndex="-1"
    >
      {children}
    </div>
  );

  function handleKeyDown(e) {
    if (isDisabled) {
      return;
    }

    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleClick(e);
    }
  }

  function handleClick(e) {
    if (onSelect) {
      onSelect(e);
    }
  }
}

MenuItem.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onSelect: PropTypes.func,
};

// maintains open/closed state
export class MenuContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };

    _.bindAll(this, ['onDocumentClick', 'onDocumentKeydown', 'onMouseLeave', 'onMouseOver', 'toggle']);
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('click', this.onDocumentClick);
    document.addEventListener('keydown', this.onDocumentKeydown);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick);
    document.removeEventListener('keydown', this.onDocumentKeydown);
  }

  render() {
    let button = null;
    if (this.props.button) {
      const classNames = classnames(this._cn('button'), {
        [this._cn('button-open')]: this.state.isOpen,
      });
      button = (
        <div className={classNames} onClick={this.toggle}>
          {this.props.button}
        </div>
      );
    }

    return (
      <div
        className={this._cn('container')}
        onClick={this.props.onClick}
        onMouseLeave={this.onMouseLeave}
        onMouseOver={this.onMouseOver}
        ref={node => (this.container = node)}
      >
        {button}
        <Menu {...this.props} isOpen={this.state.isOpen}>
          {this.props.children}
        </Menu>
      </div>
    );
  }

  /* events */

  onDocumentClick() {
    if (!this.state.isOpen || this._opening) {
      return;
    }
    this.close();
  }

  onDocumentKeydown(e) {
    if (!this.state.isOpen) {
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
      case 'Down': // IE
        e.stopPropagation();
        e.preventDefault();

        if (this.activeItemIndex < this.lastItemIndex) {
          this.activateItem(this.activeItemIndex + 1);
        } else {
          this.activateItem(0);
        }
        return;
      case 'ArrowUp':
      case 'Up': // IE
        e.stopPropagation();
        e.preventDefault();

        if (this.activeItemIndex > 0) {
          this.activateItem(this.activeItemIndex - 1);
        } else {
          this.activateItem(this.lastItemIndex);
        }
        return;
      case 'End':
        e.stopPropagation();
        e.preventDefault();

        this.activateItem(this.lastItemIndex);
        return;
      case ' ':
      case 'Enter':
      case 'Esc': // IE
      case 'Escape': {
        e.stopPropagation();

        this.close();
        return;
      }
      case 'Home':
        e.stopPropagation();
        e.preventDefault();

        this.activateItem(0);
        return;
      case 'Tab': {
        e.stopPropagation();
        e.preventDefault();
        return;
      }
      default:
        return;
    }
  }

  onMouseLeave() {
    Array.from(this.menuItems).forEach(item => item.blur());
  }

  onMouseOver(evt) {
    let menuItemIndex = _(Array.from(this.menuItems)).findIndex(menuItem => menuItem.contains(evt.target));
    if (menuItemIndex !== -1) {
      this.activeItemIndex = menuItemIndex;
      evt.target.focus();
    } else {
      if (this.menuItems[this.activeItemIndex]) {
        this.menuItems[this.activeItemIndex].blur();
      }
      delete this.activeItemIndex;
    }
  }

  /* api */

  open() {
    if (this.props.isDisabled) {
      return;
    }
    this._opening = true;
    this.setState({ isOpen: true });
    setTimeout(() => (this._opening = false), 0);
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  }

  close() {
    delete this.activeItemIndex;

    this.setState({ isOpen: false });

    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  toggle() {
    this.state.isOpen ? this.close() : this.open();
  }

  get lastItemIndex() {
    return this.menuItems.length ? this.menuItems.length - 1 : -1;
  }

  get menuItems() {
    return this.container ? this.container.querySelectorAll('.menuItem:not(.menuItem-disabled)') : [];
  }

  activateItem(index) {
    if (index < 0 || index >= this.menuItems.length) {
      return;
    }

    this.activeItemIndex = index;
    this.menuItems[index].focus();
  }

  _cn(...args) {
    const baseClass = this.props.className || 'menu';
    const res = classnames(...args);
    return res
      .split(' ')
      .map(c => `${baseClass}-${c}`)
      .join(' ');
  }
}

MenuContainer.propTypes = {
  button: PropTypes.element,
  children: PropTypes.any,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,

  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
};

MenuContainer.defaultProps = {
  onClick: _.noop,
};
