import { isArray, map } from 'lodash';

import Err from 'models/err';
import ExternalAppCardTemplate from 'models/external_apps/external_app_card_template';
import IdGenerator from 'scripts/domain/contracts/id_generator';
import qconsole from 'scripts/lib/qconsole';
import RequestAppCardTemplates from 'actions/external_apps/request_app_card_templates';

export default class ExternalAppCardTemplateGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  onFetchAllSuccess(data) {
    try {
      if (!isArray(data)) {
        throw new Error(`Expected array, got ${typeof data}`);
      }

      const entities = map(data, dto => {
        const { errors, externalAppCardId, template } = dto;
        const errorEntities = map(errors, errDto => new Err(errDto));

        const entityAttrs = {
          id: IdGenerator.newId(),
          externalAppCardId,
          template,
          errors: errorEntities.length ? errorEntities : undefined,
        };
        return ExternalAppCardTemplate.create(entityAttrs);
      });

      this.context.stores.externalAppCardTemplates.set(entities);
      this.context.stores.externalAppCardTemplates.clearErrorForLoading();
    } catch (err) {
      // If we are here, we have received a mangled payload. Report an error
      this.context.errorReporter.reportError(err, {
        extra: { responsePayload: data },
        message: 'ExternalAppCardTemplateGatewayObserver.onFetchAllSuccess: received invalid response',
      });

      // Set the error flag so that the card(s) know what to do
      this.context.stores.externalAppCardTemplates.setErrorForLoading(
        new Err({
          code: Err.Code.UNEXPECTED_ERROR,
          detail: err.message || err,
        })
      );
    } finally {
      this.context.stores.externalAppCardTemplates.resetLoading();
    }
  }

  onFetchAllError(error) {
    qconsole.error('Failed to fetch external app UI templates', JSON.stringify(error));

    this.context.stores.externalAppCardTemplates.setErrorForLoading(
      new Err({
        code: Err.Code.UNEXPECTED_ERROR,
        detail: error.message || error,
      })
    );
    this.context.stores.externalAppCardTemplates.resetLoading();
  }

  onBroadcast() {
    this.context.executeAction(RequestAppCardTemplates);
  }

  onBroadcastDelete() {
    this.context.executeAction(RequestAppCardTemplates);
  }
}
