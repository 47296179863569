import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CompanyCards from 'components/customer/profile/demo/company_cards'; // demo only
import connect from 'components/lib/connect';
import ConversationList from 'components/customer/summary/conversation_list';
import CustomerBulkEvents from 'components/customer/profile/demo/customer_bulk_events'; // demo only
import CustomerEventList from 'components/customer/profile/demo/customer_event_list'; // demo only
import CustomerEventListV2 from 'components/customer/profile/demo/customer_event_list_v2'; // demo only
import CustomerInformation from 'components/customer/summary/customer_information';
import CustomerNotesContainer from 'components/customer/summary/customer_notes';
import CustomerProfileContainer from 'components/customer/customer_profile';
import CustomerRelationships from 'components/customer/summary/customer_relationships';
import CustomerRelationshipsDemo from 'components/customer/profile/demo/customer_relationships';
import ErrorBoundary from 'components/common/utilities/error_boundary';
import ExternalAppCard from 'components/customer/external_app_card/components/external_app_card';
import CustomerFlightTransactions from 'components/customer/summary/customer_flight_transactions';
import CustomerTransactions from 'components/customer/summary/customer_transactions';
import CustomAttributes from 'components/customer/summary/custom_attributes';
import FlexibleProfileCardV2 from 'components/customer/summary/flexible_profile_card_v2';
import { getDefaultProfileCards } from 'models/customer_profile_def';
import Payouts from 'components/customer/profile/demo/payouts'; // demo only
import ProfileCardType from 'models/configuration/profile_card_type';
import ProfileErrorCard from 'components/customer/profile/profile_error_card';
import qconsole from 'scripts/lib/qconsole';
import TaskManager from './summary/task_manager';

export function CustomerSummary({ customerProfileDef, hasProfile, additionalFeatures }) {
  if (!hasProfile || !customerProfileDef) {
    return null;
  }

  return <CustomerProfile data-aid="customerProfile">{renderProfileCards()}</CustomerProfile>;

  function renderProfileCards() {
    // If you are adding a new card type, be sure to add it to:
    // 1. ProfileCardType
    // 2. The getProfileCardComponent map below
    // 3. Default profile cards returned by `getDefaultProfileCards` (if necessary)
    // 4. Any custom profile card defs stored in a customer's customerProfileDef
    // - likely contact someone on professional services for help with that
    const profileCardDefs = _.cloneDeep(_.get(customerProfileDef, 'profileCards', getDefaultProfileCards()));

    return _.map(profileCardDefs, (profileCardDef, index) => {
      const card = getProfileCardComponent(`customerSummary_${index}`, profileCardDef, additionalFeatures);
      if (!card) return null;

      return (
        <ErrorBoundary
          errorMeta={{ errorBoundary: 'Profile Card', profileCardType: profileCardDef.type }}
          key={`customerSummary_${index}_error_boundary`}
          renderError={() => <ProfileErrorCard data-aid={`${_.toLower(profileCardDef.type)}_error_card`} />}
        >
          {card}
        </ErrorBoundary>
      );
    });
  }
}

CustomerSummary.propTypes = {
  customerProfileDef: PropTypes.object,
  hasProfile: PropTypes.bool.isRequired,
  additionalFeatures: PropTypes.object,
};

const CustomerProfile = styled.div`
  background-color: ${p => p.theme.colors.gray100};
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  min-width: 0;
  overflow-y: auto;
  padding: 16px 16px 0;
  width: 360px;
  z-index: 3;
`;

//
// When we need a feature flag or a conditional switch, we normally put it in `additionalFeatures`
// that gets populated in `mapStateToProps`
//
function getProfileCardComponent(key, profileCardDef, additionalFeatures) {
  switch (profileCardDef.type) {
    case ProfileCardType.BASIC_PROFILE:
      return <CustomerInformation key={key} profileCardDef={profileCardDef} />;
    case ProfileCardType.CUSTOM_ATTRS:
      return <CustomAttributes key={key} profileCardDef={profileCardDef} />;
    case ProfileCardType.CONTACT_INFO:
      return <CustomerProfileContainer key={key} />;
    case ProfileCardType.CONVERSATION_LIST:
      return <ConversationList key={key} />;
    case ProfileCardType.FLIGHT_DEMO:
      return <CustomerFlightTransactions key={key} profileCardDef={profileCardDef} />;
    case ProfileCardType.FLEXIBLE_CARD:
      return getFlexibleCardV2(key, profileCardDef);
    case ProfileCardType.TRANSACTIONS:
      return <CustomerTransactions key={key} profileCardDef={profileCardDef} />;
    case ProfileCardType.DETAILS:
      return <CustomerNotesContainer key={key} />;
    case ProfileCardType.UPCOMING_EVENTS:
      return <CustomerEventList key={key} type="upcomingEvents" />;
    case ProfileCardType.RECENT_EVENTS:
      return <CustomerEventList key={key} type="recentEvents" />;
    case ProfileCardType.EVENTS:
      return <CustomerEventListV2 key={key} type="events" />;

    case ProfileCardType.EXTERNAL_APP_CARD:
      if (additionalFeatures.enableExternalAppCard) {
        return <ExternalAppCard key={key} profileCardDef={profileCardDef} />;
      } else {
        return null;
      }

    case ProfileCardType.SUBSCRIPTIONS:
      return <CustomerEventListV2 key={key} type="subscriptions" />;
    case ProfileCardType.COMPANY_CARDS:
      return <CompanyCards key={key} type="companyCards" />;
    case ProfileCardType.DEMO_RELATIONSHIPS:
      return <CustomerRelationshipsDemo key={key} type="relationships" />;
    case ProfileCardType.RELATIONSHIPS:
      return <CustomerRelationships key={key} type="relationships" />;
    case ProfileCardType.VERIFICATION_QUESTIONS:
      return <CustomerEventList key={key} type="verificationQuestions" />;
    case ProfileCardType.BULK_EVENTS:
      return <CustomerBulkEvents key={key} type="bulkEvents" />;
    case ProfileCardType.PAYOUTS:
      return <Payouts key={key} type="payouts" />;
    case ProfileCardType.TASKS:
      return <TaskManager key={key} type="taskManager" />;

    default:
      qconsole.error(`unsupported profileCardDef type requested: ${profileCardDef.type}`);
      return;
  }
}

function getFlexibleCardV2(key, profileCardDef) {
  const configKey = _.get(profileCardDef, 'properties.cardParameters.configKey');

  return <FlexibleProfileCardV2 configurationKey={configKey} key={key} />;
}

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  const profileProvider = getProvider('profile');
  const hasProfile = profileProvider.isLoading() || !!profileProvider.get();
  const customerProfileDefProvider = getProvider('customerProfileDef');

  return {
    customerProfileDef: customerProfileDefProvider.get(),
    additionalFeatures: {
      enableExternalAppCard: isFeatureEnabled('enableExternalAppCard'),
    },
    hasProfile,
    isLoading: customerProfileDefProvider.isLoading(),
  };
}

export default connect(mapStateToProps)(CustomerSummary);
