import React, { useContext } from 'react';
import styled from 'styled-components';

import ConversationItemType from 'models/conversation_item_type';
import ItemContext from 'components/customer/conversation_history/conversation_items_v2/item_context';
import { MenuItem as DefaultMenuItem } from 'components/lib/menu_deprecated';

function MenuItem(props) {
  const { item } = useContext(ItemContext);

  let isWide = props.isWide || false;
  if (item.contentType() === ConversationItemType.CUSTOM_CHANNEL_MESSAGE) {
    isWide = true;
  }

  const handleClick = e => {
    props.onSelect?.(e);
  };

  return <StyledMenuItem {...props} $isWide={isWide} onClick={handleClick} />;
}

const StyledMenuItem = styled(DefaultMenuItem)`
  padding: 8px ${p => (p.$isWide ? '36px' : '16px')};
  position: relative;

  &:focus {
    padding: 8px ${p => (p.$isWide ? '36px' : '16px')};

    svg {
      fill: ${p => p.theme.colors.green400};
    }
  }
`;

export default MenuItem;
