export default class RequestAppCardTemplates {
  constructor(context) {
    this.context = context;
  }

  run() {
    // Have we already requested the data? If yes, then don't re-request
    if (this.context.stores.externalAppCardTemplates.isLoading()) {
      return;
    }

    this.context.stores.externalAppCardTemplates.clearErrorForLoading();
    this.context.gateways.externalAppCardTemplates.requestAll();
    this.context.stores.externalAppCardTemplates.setLoading();
  }
}
