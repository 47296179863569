import _ from 'lodash';
import faker from 'faker';
import { statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class AITranslationService {
  constructor(getDatabase, pubsub) {
    this.getDatabase = getDatabase;
    this.pubsub = pubsub;
  }

  getRoutes() {
    return {
      '/api/v1/orgs/:orgId/translation': {
        POST: this.translate.bind(this),
      },
    };
  }

  translate(attrs, onReceive) {
    if (attrs.text === '') {
      const status = 400;
      onReceive(new Error(statusText(status)), {
        status,
        statusText: statusText(status),
        response: {
          errors: [{ attr: 'text', code: 'blank', detail: 'cannot be blank' }],
        },
      });
      return;
    }

    if (attrs.targetLanguage === '') {
      const status = 400;
      onReceive(new Error(statusText(status)), {
        status,
        statusText: statusText(status),
        response: {
          errors: [{ attr: 'targetLanguage', code: 'blank', detail: 'cannot be blank' }],
        },
      });
      return;
    }

    const text = createFakeSentences(3).join(' ');
    const status = 200;
    onReceive(null, {
      status,
      statusText: statusText(status),
      response: {
        text,
        sourceLanguage: 'en',
        targetLanguage: attrs.targetLanguage,
        model: 'gpt-4o-mini',
        provider: 'openai',
        translatedAt: '2025-03-11T00:00:00Z',
      },
    });
  }
}

function createFakeSentences(numberOfSentences) {
  const ret = [];
  for (let i = 0; i < numberOfSentences; i++) {
    ret.push(_.capitalize(faker.lorem.sentence()));
  }
  return ret;
}
