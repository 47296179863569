import classnames from 'classnames';
import React, { useState } from 'react';

import { PortalledPopover } from 'components/common/lib/_popover';
import MenuItem from 'components/customer/conversation_history/conversation_items_v2/menu/conversation_item_menu_item';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';
import TranslationLanguages from 'models/languages/translation_languages';
import TranslateIntoSearchMenuContainerWrapper from './translate_into_search_menu_container_wrapper';
import useWindowSize from 'components/hooks/use_window_size';

export default function TranslateIntoMenuItem({ onSelect, onClose, targetRef }) {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { windowHeight, windowWidth } = useWindowSize();

  const handleSelectLanguage = code => {
    onSelect?.(code);
    onClose?.();
  };

  const handleOpenSearch = () => {
    setIsSearchOpen(true);
  };

  return (
    <PortalledPopover
      autoPosition
      bounds={{
        bottom: windowHeight - 70,
        right: windowWidth - 10,
        top: 70,
      }}
      className="translateMenu"
      isVisible
      position="bottom"
      targetElement={targetRef.current}
    >
      <OutsideClickHandler onClickOutside={onClose}>
        {isSearchOpen ? (
          <TranslateIntoSearchMenuContainerWrapper
            availableLanguages={TranslationLanguages}
            onSelectLanguage={handleSelectLanguage}
          />
        ) : (
          <>
            <MenuItem
              className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-language')}
              data-aid={`translate-into-en`}
              isWide
              onSelect={() => handleSelectLanguage('en')}
            >
              <span>English</span>
            </MenuItem>
            <MenuItem
              className={classnames('conversationItemMenu-menuItem', 'conversationItemMenu-menuItem-language')}
              data-aid={`translate-into-es`}
              isWide
              onSelect={() => handleSelectLanguage('es')}
            >
              <span>Spanish</span>
            </MenuItem>
            <MenuItem
              className={classnames('conversationItemMenu-menuItem')}
              data-aid={`translate-into-see-more`}
              isWide
              onSelect={handleOpenSearch}
            >
              <span>See more</span>
            </MenuItem>
          </>
        )}
      </OutsideClickHandler>
    </PortalledPopover>
  );
}
