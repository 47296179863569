import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';
import _ from 'lodash';

/**
 * This service provides a limited subset of the "external app configuration" service
 * routes in Supernova
 */
export default class ExternalAppCardService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  getConfiguredUITemplates(payload, callback, path, params) {
    const { orgId } = params || {};

    if (!orgId) {
      return callback(Error(statusText(404)), {
        status: 404,
        statusText: statusText(404),
      });
    }

    const database = getDBForOrg(orgId, this.getDatabase, callback);
    if (!database) return;

    const uiTemplates = _.get(database, 'externalAppConfiguredUITemplates');
    if (!_.isArray(uiTemplates)) {
      const errorText = `Expected an array of UI Template Configurations, found "${typeof uiTemplates}" instead`;
      return callback(Error(errorText), {
        status: 500,
        statusText: errorText,
      });
    }

    setTimeout(() => {
      callback(null, {
        status: 200,
        statusText: statusText(200),
        response: uiTemplates,
      });
    }, Math.ceil(5000 * Math.random()));
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/external-app-card/ui-templates': {
          GET: this.getConfiguredUITemplates,
        },
      },
      this
    );
  }
}

function getDBForOrg(orgId, getDatabase, responseCallback) {
  let database;
  try {
    database = getDatabase(orgId);
    if (!database) {
      responseCallback(Error(`No database for org ${orgId}`), {
        status: 500,
        statusText: `No database for org ${orgId}`,
      });
    }
  } catch (err) {
    database = undefined;
    responseCallback(Error(`Unable to get database for org ${orgId}: ${err.message}`), {
      status: 500,
      statusText: `Unable to get database for org ${orgId}: ${err.message}`,
    });
  }

  return database;
}
